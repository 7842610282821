















































































import Vue from 'vue';
import { STANDARD_AREAS_MAPPING_LIST } from '@/domain/area/queries/query';
import {
  DisplayAreaAndStandardAreaMapParam,
  StandardArea,
  StandardAreaEdge,
  StandardAreaFilter,
} from '@/domain/area/model/displayArea';
import Spinner from '@/components/Spinner.vue';
import StandardAreaCardView from '@/domain/area/components/StandardAreaCardView.vue';
import { StandardAreaService } from '@/domain/area/service/StandardAreaService';
import { apolloClient } from '@/apolloClient';
import _ from 'lodash';

const standardAreaService = new StandardAreaService(apolloClient);

export default Vue.extend({
  name: 'StandardAreaMapping',
  components: {
    StandardAreaCardView,
    Spinner,
  },
  props: {
    displayNameArray: {
      type: Array,
      default: () => [],
    },
    displayAreaId: {
      type: String,
    },
    displayAreaName: {
      type: String,
      default: '',
    },
    selectDisplayArea: {
      type: Object,
    },
    registration: {
      type: Boolean,
      default: true,
    },
  },
  data(): {
    page: number;
    size: number;
    filter: StandardAreaFilter;
    standardAreas: StandardArea[];
    standardAreaList: [];
    loading: boolean;
    totalCount: number;
    allStandardAreas: [];
    depthIndexes: [2, 3, 4, 5];
    selectAreas: [];
    validationParentAreaArray: [];
    mappedDisplayAreaName: string;
  } {
    return {
      page: 1,
      size: 10,
      filter: {
        depth: 2,
      } as StandardAreaFilter,
      standardAreas: [],
      standardAreaList: [],
      loading: true,
      totalCount: 0,
      allStandardAreas: [],
      depthIndexes: [2, 3, 4, 5],
      selectAreas: [],
      validationParentAreaArray: [],
      mappedDisplayAreaName: '',
    };
  },
  computed: {
    displayAreaNameValue() {
      if (!this.registration) {
        (this as any).displayNameArray = [];
      }

      return this.displayNameArray;
    },
  },
  async created() {
    for (const depth of this.$data.depthIndexes) {
      const result = await this.standardArea(Number(depth));
      this.$data.allStandardAreas.push(result);
    }

    this.$data.loading = false;
  },
  mounted() {
    if (this.registration) {
      this.$data.mappedDisplayAreaName = this.displayAreaName;
    }
  },
  methods: {
    closeModal(): void {
      this.$emit('close');
    },
    async standardArea(depth: number): Promise<StandardArea[] | void> {
      let hasNextPage = true;
      let standardAreaArray = [] as StandardArea[];
      let page = 1;
      try {
        while (hasNextPage) {
          const result = await this.$apollo.query({
            query: STANDARD_AREAS_MAPPING_LIST,
            variables: {
              filter: {
                depth,
              },
              page,
              size: 10000,
            },
          });

          hasNextPage = result.data.area.standardAreas.pageInfo.hasNextPage;

          result.data.area.standardAreas.edges.map(
            (standardArea: StandardAreaEdge) => {
              standardAreaArray.push(standardArea.node);
            }
          );

          page++;
        }

        return standardAreaArray;
      } catch (error) {
        console.error(error);
      }
    },
    selectArea(selectArea: StandardArea): void | boolean {
      if (!this.validationSelectArea(selectArea)) {
        this.$modal.show({
          title: '매핑 실패!',
          message: '상위 지역을 매핑 할 수 없습니다.',
          type: 'warning',
        });

        return false;
      }
      this.$data.selectAreas.push(selectArea);
      this.$data.selectAreas = _.uniqBy(this.$data.selectAreas, 'id');
    },
    validationSelectArea(standardArea: StandardArea): boolean {
      const parentId = this.$data.validationParentAreaArray.find(
        (id: string) => {
          if (standardArea.id === id) {
            return id;
          }
        }
      );

      if (parentId) {
        return false;
      }

      this.$data.validationParentAreaArray.push(standardArea.parent.id);

      this.$data.validationParentAreaArray = Array.from(
        new Set(this.$data.validationParentAreaArray)
      );

      return true;
    },
    registerDisplayArea(): void | boolean {
      if (this.$data.selectAreas.length === 0) {
        this.$modal.show({
          title: '지역 매핑 저장 실패!',
          message:
            '표준 지역을 매핑하기 위해서는 최소 하나의 표준지역을 선택해주세요.',
          type: 'warning',
        });

        return false;
      }
      console.log(this.$data.selectAreas);
      const standardAreaIds = this.$data.selectAreas.map(
        (standardArea: StandardArea) => standardArea.id
      );

      const param: DisplayAreaAndStandardAreaMapParam = {
        standardAreaIds,
        displayAreaId: this.displayAreaId,
      };

      const mappedStandardAreas = this.$data.selectAreas.map(
        (standardArea: StandardArea) => {
          return {
            id: standardArea.id,
            name: standardArea.name,
          };
        }
      );

      this.$modal.show(
        {
          title: '표준 지역 매핑',
          message: '표준 지역을 연결 하시겠습니까?',
          type: 'info',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            console.log(this.$data.selectAreas);

            await standardAreaService.mapDisplayAreaWithStandardAreas(param);

            this.$modal.show(
              {
                title: '표준 지역 매핑 성공!',
                message: '표준 지역 매핑에 성공했습니다.',
                type: 'success',
              },
              () => {
                this.$emit('addMappingDisplayArea', mappedStandardAreas);
                this.closeModal();
              }
            );
          } catch (error) {
            this.$modal.show({
              title: '표준 지역 매핑 실패',
              message:
                '표준 지역 매핑에 실패 했습니다.\n관리자에게 문의해주에요.',
              type: 'warning',
            });
          }
        }
      );
    },
    removeSelectStandardArea(index: number): void {
      this.$data.selectAreas.splice(index, 1);
    },
    async modifyMappingArea(): Promise<void> {
      console.log(this.$data.selectAreas);
      console.log(this.displayAreaId);
      const standardAreaIds = this.selectDisplayArea.mappedStandardAreas.map(
        (standardArea: StandardArea) => {
          return standardArea.id;
        }
      );

      const param: DisplayAreaAndStandardAreaMapParam = {
        standardAreaIds,
        displayAreaId: this.displayAreaId,
      };

      if (this.$data.selectAreas.length === 0) {
        this.$modal.show({
          title: '지역 매핑 실패!',
          message: '하나 이상의 지역을 선택해 주세요.',
          type: 'warning',
        });

        return;
      }

      const updateStandardAreaIds = this.$data.selectAreas.map(
        (standardArea: StandardArea) => {
          return standardArea.id;
        }
      );

      const updateParam: DisplayAreaAndStandardAreaMapParam = {
        standardAreaIds: updateStandardAreaIds,
        displayAreaId: this.displayAreaId,
      };

      const mappedStandardAreas = this.$data.selectAreas.map(
        (standardArea: StandardArea) => {
          return {
            id: standardArea.id,
            name: standardArea.name,
          };
        }
      );

      this.$modal.show(
        {
          title: '표준 지역 매핑 수정',
          message: '표준 지역 매핑을 수정 하시겠습니까?',
          type: 'info',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            await standardAreaService.unMapDisplayAreaWithStandardAreas(param);
            await standardAreaService.mapDisplayAreaWithStandardAreas(
              updateParam
            );

            this.$modal.show(
              {
                title: '표준 지역 매핑 수정 성공!',
                message: '표준 지역 수정을 성공했습니다.',
                type: 'success',
              },
              () => {
                this.$emit('addMappingDisplayArea', mappedStandardAreas);
                this.closeModal();
              }
            );
          } catch (error) {
            console.error(error);
          }
        },
        () => {
          return;
        }
      );
    },
  },
});
