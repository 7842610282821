














































































import Vue from 'vue';
import { DisplayArea } from '@/domain/area/model/displayArea';
import DisplayAreaCreate from '@/domain/area/components/modal/DisplayAreaCreate.vue';
import { ACTIVE, INACTIVE } from '@/domain/area/utils/common';
import { ToggleButton } from 'vue-js-toggle-button';
import { DisplayAreaService } from '@/domain/area/service/DisplayAreaService';
import { apolloClient } from '@/apolloClient';
import { CommonState } from '@frientrip/domain';

const displayAreaService = new DisplayAreaService(apolloClient);

export default Vue.extend({
  name: 'AreaCardView',
  components: {
    DisplayAreaCreate,
    ToggleButton,
  },
  props: {
    displayArea: {
      type: Object,
      default: () => {
        return {};
      },
    },
    depth: {
      type: Number,
    },
    parentId: {
      type: String,
    },
    selectDepth: {
      type: Number,
    },
  },
  data(): {
    showDisplayAreaModal: boolean;
    ACTIVE: string;
    INACTIVE: string;
    registration: boolean;
    displayName: string;
    id: string;
    activeIndex: undefined | number;
  } {
    return {
      showDisplayAreaModal: false,
      ACTIVE: ACTIVE,
      INACTIVE: INACTIVE,
      registration: true,
      displayName: '',
      id: '',
      activeIndex: undefined,
    };
  },
  computed: {
    areas() {
      return this.displayArea;
    },
  },
  methods: {
    selectArea(area: DisplayArea, index: number) {
      this.$data.activeIndex = index;
      this.$emit('addChildren', area);
    },
    addAreaDepth(): void {
      this.$data.showDisplayAreaModal = true;
    },
    closeModal(): void {
      this.$data.displayName = '';
      this.$data.registration = true;
      this.$data.showDisplayAreaModal = false;
    },
    addDisplayArea(displayArea: DisplayArea): void {
      this.$emit('addDisplayArea', displayArea);
    },
    async changeStatusDisplayArea(area: DisplayArea): Promise<void> {
      const status = area.status;
      const id = area.id;
      const title =
        status === ACTIVE ? '전시 지역 비활성화' : '전시 지역 활성화';

      this.$modal.show(
        {
          title: title,
          type: 'info',
          cancelText: '취소',
          showCancelButton: true,
        },
        async () => {
          //toggle button sync
          if (status === ACTIVE) {
            await (this as any).inactiveDisplayArea(id);
            area.status = CommonState.INACTIVE;
          } else {
            await (this as any).activeDisplayArea(id);
            area.status = CommonState.ACTIVE;
          }
        },
        () => {
          return;
        }
      );
    },
    async activeDisplayArea(id: number): Promise<void> {
      try {
        await displayAreaService.activeDisplayArea(id);

        this.$modal.show({
          title: '전시 지역 활성화 성공!',
          message: '전시 지역 활성화에 성공하였습니다.',
          type: 'success',
        });
      } catch (error) {
        this.$modal.show({
          title: '전시 지역 활성화 실패!',
          message: '전시 지역 활성화에 실패 했습니다.',
          type: 'warning',
        });
      }
    },
    async inactiveDisplayArea(id: number): Promise<void> {
      try {
        await displayAreaService.inactiveDisplayArea(id);

        this.$modal.show({
          title: '전시 지역 비활성화 성공!',
          message: '전시 지역 비활성화에 성공했습니다.',
          type: 'success',
        });
      } catch (error) {
        this.$modal.show({
          title: '전시 지역 비활성화 실패!',
          message: '전시 지역 비활성화에 실패 했습니다.',
          type: 'warning',
        });
      }
    },
    showModifyDisplayAreaNameModal(area: DisplayArea): void {
      this.$data.id = area.id;
      this.$data.displayName = area.name;
      this.$data.registration = false;
      this.$data.showDisplayAreaModal = true;
    },
    modifyDisplayAreaName(): void {
      this.$emit('modifyDisplayAreaName');
    },
  },
});
