import gql from 'graphql-tag';

export const DISPLAY_AREA_CHILDREN_FRAGMENT = gql`
  fragment areaDisplayChildrenFiled on DisplayArea {
    id
    name
    depth
    status
    parent {
      id
      name
      depth
      status
      mappedStandardAreas {
        id
        name
      }
    }
    children {
      id
      name
      depth
      status
      mappedStandardAreas {
        id
        name
      }
      children {
        id
        name
        depth
        status
        mappedStandardAreas {
          id
          name
        }
      }
    }
    mappedStandardAreas {
      id
      name
    }
  }
`;

export const STANDARD_AREA_CHILDREN_FRAGMENT = gql`
  fragment standardAreaChildrenFiled on StandardArea {
    id
    name
    depth
    parent {
      id
      name
      depth
    }
    children {
      id
      name
      depth
    }
  }
`;

export const DISPLAY_AREA_LIST = gql`
  query ($filter: DisplayAreaFilter, $page: Int, $size: Int) {
    area {
      displayAreas(filter: $filter, page: $page, size: $size) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            id
            name
            depth
            seqNo
            status
            parent {
              ...areaDisplayChildrenFiled
            }
            children {
              ...areaDisplayChildrenFiled
            }
            mappedStandardAreas {
              id
              name
              depth
              seqNo
            }
          }
        }
      }
    }
  }
  ${DISPLAY_AREA_CHILDREN_FRAGMENT}
`;

export const STANDARD_AREAS = gql`
  query ($filter: StandardAreaFilter, $page: Int, $size: Int) {
    area {
      standardAreas(filter: $filter, page: $page, size: $size) {
        totalCount
        edges {
          cursor
          node {
            id
            name
            depth
            seqNo
            status
            parent {
              ...standardAreaChildrenFiled
            }
            children {
              ...standardAreaChildrenFiled
            }
            #            mappedDisplayAreas
          }
        }
      }
    }
  }
  ${STANDARD_AREA_CHILDREN_FRAGMENT}
`;

export const STANDARD_AREAS_MAPPING_LIST = gql`
  query ($filter: StandardAreaFilter, $page: Int, $size: Int) {
    area {
      standardAreas(filter: $filter, page: $page, size: $size) {
        pageInfo {
          hasNextPage
        }
        totalCount
        edges {
          cursor
          node {
            id
            name
            depth
            parent {
              id
            }
          }
        }
      }
    }
  }
`;
