import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {
  DisplayArea,
  DisplayAreaCreateParam,
  DisplayAreaModifyParam,
} from '@/domain/area/model/displayArea';
import {
  ACTIVE_DISPLAY_AREA,
  CREATE_DISPLAY_AREA,
  INACTIVE_DISPLAY_AREA,
  MODIFY_DISPLAY_AREA,
} from '@/domain/area/queries/mutation';

export class DisplayAreaService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async createDisplayArea(param: DisplayAreaCreateParam): Promise<DisplayArea> {
    const response = await this.apollo.mutate({
      mutation: CREATE_DISPLAY_AREA,
      variables: { param },
    });

    return response.data.createDisplayArea;
  }

  async updateDisplayArea(param: DisplayAreaModifyParam): Promise<DisplayArea> {
    const response = await this.apollo.mutate({
      mutation: MODIFY_DISPLAY_AREA,
      variables: { param },
    });

    return response.data.modifyDisplayArea;
  }

  async activeDisplayArea(id: number): Promise<DisplayArea> {
    const response = await this.apollo.mutate({
      mutation: ACTIVE_DISPLAY_AREA,
      variables: { id },
    });

    return response.data.activeDisplayArea;
  }

  async inactiveDisplayArea(id: number): Promise<DisplayArea> {
    const response = await this.apollo.mutate({
      mutation: INACTIVE_DISPLAY_AREA,
      variables: { id },
    });

    return response.data.inactiveDisplayArea;
  }
}
