

































import Vue from 'vue';
import { StandardArea } from '@/domain/area/model/displayArea';
import { Table, TableColumn } from 'element-ui';
import get = Reflect.get;

export default Vue.extend({
  name: 'StandardAreaCardView',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    standardAreas: {
      type: Array,
      default: () => [],
    },
    depth: {
      type: Number,
    },
    displayName: {
      type: Array,
      default: () => [],
    },
  },
  data(): {
    showCloseModal: boolean;
    search: string;
  } {
    return {
      showCloseModal: false,
      search: '',
    };
  },
  computed: {
    filterStandardArea() {
      if (this.$data.search) {
        const searchArea = this.$data.search.split(',');
        let result: any[] = [];
        searchArea.map((area: any) => {
          const searchResult = this.standardAreas.filter(
            (standardArea: any) => {
              return (
                area === standardArea.name || standardArea.name.includes(area)
              );
            }
          );

          if (result.length === 0) {
            result = searchResult;
          } else {
            result = result.concat(searchResult);
          }
        });

        return result;
      }

      return this.standardAreas;
    },
  },
  created() {
    if (this.displayName.length > 0) {
      this.$data.search = this.displayName.join();
    }
  },
  methods: {
    selectArea(standardArea: StandardArea) {
      this.$emit('selectArea', standardArea);
    },
    validationSelectArea(standardArea: StandardArea): string {
      const regex = /[^1-9]/g;
      const validationCode = standardArea.id.replace(regex, '');
      return validationCode;
    },
  },
});
