








































































import Vue from 'vue';
import AreaCardView from '@/domain/area/components/AreaCardView.vue';
import { DISPLAY_AREA_LIST } from '@/domain/area/queries/query';
import {
  DisplayArea,
  DisplayAreaConnection,
  DisplayAreaEdge,
  DisplayAreaFilter,
} from '@/domain/area/model/displayArea';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import Spinner from '@/components/Spinner.vue';
import StandardAreaMapping from '@/domain/area/components/modal/StandardAreaMapping.vue';

export default Vue.extend({
  name: 'DisplayAreaContainer',
  components: {
    StandardAreaMapping,
    AreaCardView,
    Spinner,
  },
  data(): {
    loading: boolean;
    page: number;
    pageSize: number;
    filter: DisplayAreaFilter;
    displayAreas: DisplayAreaConnection;
    depth: number;
    skip: boolean;
    allDepthDisplayAreas: [];
    depthArray: [];
    childrenDepth: 3;
    showStandardMappingModal: boolean;
    selectDisplayArea: [];
    displayNameArray: [];
    displayAreaId: string;
    displayAreaName: string;
    showDisplayAreaModal: boolean;
    displayArea: DisplayArea;
    registration: boolean;
    selectDepth: undefined | number;
  } {
    return {
      loading: true,
      page: Number(this.$route.query.page) || 1,
      pageSize: Number(this.$route.query.pageSize) || 30,
      filter: {
        id: '',
        depth: 1,
        parentId: '',
      } as DisplayAreaFilter,
      displayAreas: {} as DisplayAreaConnection,
      depth: 4,
      skip: true,
      allDepthDisplayAreas: [],
      depthArray: [],
      childrenDepth: 3,
      showStandardMappingModal: false,
      selectDisplayArea: [],
      displayNameArray: [],
      displayAreaId: '',
      displayAreaName: '',
      showDisplayAreaModal: false,
      displayArea: {} as DisplayArea,
      registration: true,
      selectDepth: undefined,
    };
  },
  mounted() {
    for (let num = 0; num < this.$data.childrenDepth; num++) {
      this.$data.allDepthDisplayAreas.push({
        areas: [],
        parentId: '',
      });
    }
  },
  methods: {
    addChildren(area: DisplayArea) {
      this.$data.selectDepth = area.depth;
      this.$data.displayArea = area;
      this.$data.selectDisplayArea =
        area.mappedStandardAreas.length > 0 ? area : {};
      this.$data.displayAreaId = area.id;

      const children = area.children ? area.children : {};
      const parentId = area.id;
      const childDepth = area.depth + 1;
      const areas = {
        areas: children,
        parentId,
      };

      if (typeof this.$data.selectDisplayArea === 'object') {
        this.$data.displayAreaName = area.name;
        (this as any).replaceDisplayName(area.name);
      }

      this.$data.allDepthDisplayAreas[area.depth] = areas;

      if (this.$data.allDepthDisplayAreas[childDepth]) {
        this.$set(this.$data.allDepthDisplayAreas[childDepth], 'parentId', '');
      }

      this.$forceUpdate();
    },
    standardAreaMapping() {
      this.$data.showStandardMappingModal = true;
    },
    close() {
      this.$data.registration = true;
      this.$data.showStandardMappingModal = false;
    },
    addDisplayArea(displayArea: DisplayArea): void {
      if (displayArea) {
        const depth = displayArea.depth - 1;
        this.$data.allDepthDisplayAreas[depth].areas.push(displayArea);
      }
    },
    replaceDisplayName(displayName: string): void {
      if (displayName.length > 0) {
        const regex = /[\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"\·]/gi;
        this.$data.displayNameArray = displayName.split(regex);
      }
    },
    addMappingDisplayArea(mappedArea: any): void {
      this.$data.displayArea.mappedStandardAreas = mappedArea;
    },
    modifyDisplayAreaName(): void {
      this.$apollo.queries.displayAreas.refetch();
    },
    showStandardAreasModModal(): void {
      this.$data.registration = false;
      this.$data.showStandardMappingModal = true;
    },
  },
  apollo: {
    displayAreas: {
      query: DISPLAY_AREA_LIST,
      variables() {
        return {
          page: this.$data.page,
          size: this.$data.pageSize,
          filter: this.$data.filter,
        };
      },
      error(e: ApolloError): void {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          area: { displayAreas: DisplayAreaConnection };
        }>
      ) {
        console.log('display query');
        this.$data.displayAreas = result.data.area.displayAreas.edges.map(
          (displayArea: DisplayAreaEdge) => {
            return displayArea.node;
          }
        );

        const areas = {
          areas: this.$data.displayAreas,
          parentId: '0',
        };
        this.$data.allDepthDisplayAreas.unshift(areas);
        this.$data.loading = false;
      },
      fetchPolicy: 'no-cache',
      update: data => data.area.displayAreas,
    },
  },
});
