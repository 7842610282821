import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {
  MAPPING_STANDARD_AREA,
  UN_MAP_DISPLAY_AREA,
} from '@/domain/area/queries/mutation';
import {
  DisplayAreaAndStandardAreaMap,
  DisplayAreaAndStandardAreaMapParam,
} from '@/domain/area/model/displayArea';

export class StandardAreaService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async mapDisplayAreaWithStandardAreas(
    param: DisplayAreaAndStandardAreaMapParam
  ): Promise<DisplayAreaAndStandardAreaMap> {
    const response = await this.apollo.mutate({
      mutation: MAPPING_STANDARD_AREA,
      variables: { param },
    });

    return response.data.mapDisplayAreaWithStandardAreas;
  }

  async unMapDisplayAreaWithStandardAreas(
    param: DisplayAreaAndStandardAreaMapParam
  ): Promise<DisplayAreaAndStandardAreaMap> {
    const response = await this.apollo.mutate({
      mutation: UN_MAP_DISPLAY_AREA,
      variables: { param },
    });

    return response.data.unMapDisplayAreaWithStandardAreas;
  }
}
