import gql from 'graphql-tag';
import { DISPLAY_AREA_CHILDREN_FRAGMENT } from '@/domain/area/queries/query';

export const CREATE_DISPLAY_AREA = gql`
  mutation ($param: DisplayAreaCreateParam!) {
    createDisplayArea(param: $param) {
      id
      name
      depth
      seqNo
      status
      parent {
        ...areaDisplayChildrenFiled
      }
      children {
        ...areaDisplayChildrenFiled
      }
      mappedStandardAreas {
        id
        name
        depth
        seqNo
      }
    }
  }
  ${DISPLAY_AREA_CHILDREN_FRAGMENT}
`;

export const MAPPING_STANDARD_AREA = gql`
  mutation ($param: DisplayAreaAndStandardAreaMapParam!) {
    mapDisplayAreaWithStandardAreas(param: $param) {
      displayAreaId
      standardAreaId
      status
    }
  }
`;

export const MODIFY_DISPLAY_AREA = gql`
  mutation ($param: DisplayAreaModifyParam!) {
    modifyDisplayArea(param: $param) {
      id
      name
      depth
      seqNo
      status
      parent {
        ...areaDisplayChildrenFiled
      }
      children {
        ...areaDisplayChildrenFiled
      }
      mappedStandardAreas {
        id
        name
        depth
        seqNo
      }
    }
  }
  ${DISPLAY_AREA_CHILDREN_FRAGMENT}
`;

export const ACTIVE_DISPLAY_AREA = gql`
  mutation ($id: ID!) {
    activeDisplayArea(id: $id) {
      id
      name
      depth
      seqNo
      status
      parent {
        ...areaDisplayChildrenFiled
      }
      children {
        ...areaDisplayChildrenFiled
      }
      mappedStandardAreas {
        id
        name
        depth
        seqNo
      }
    }
  }
  ${DISPLAY_AREA_CHILDREN_FRAGMENT}
`;

export const INACTIVE_DISPLAY_AREA = gql`
  mutation ($id: ID!) {
    inactiveDisplayArea(id: $id) {
      id
      name
      depth
      seqNo
      status
      parent {
        ...areaDisplayChildrenFiled
      }
      children {
        ...areaDisplayChildrenFiled
      }
      mappedStandardAreas {
        id
        name
        depth
        seqNo
      }
    }
  }
  ${DISPLAY_AREA_CHILDREN_FRAGMENT}
`;

export const UN_MAP_DISPLAY_AREA = gql`
  mutation ($param: DisplayAreaAndStandardAreaMapParam!) {
    unMapDisplayAreaWithStandardAreas(param: $param) {
      displayAreaId
      standardAreaId
      status
    }
  }
`;
