






































import Vue from 'vue';

import {
  DisplayArea,
  DisplayAreaCreateParam,
  DisplayAreaFilter,
  DisplayAreaModifyParam,
  StandardArea,
} from '@/domain/area/model/displayArea';
import { DisplayAreaService } from '@/domain/area/service/DisplayAreaService';
import { apolloClient } from '@/apolloClient';

const displayAreaService = new DisplayAreaService(apolloClient);

export default Vue.extend({
  name: 'DisplayAreaCreate',
  components: {},
  props: {
    depth: {
      type: Number,
      default: 0,
    },
    parentId: {
      type: String,
    },
    registration: {
      type: Boolean,
      default: true,
    },
    displayName: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    displayArea: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data(): {
    page: number;
    size: number;
    filter: DisplayAreaFilter;
    standardAreas: StandardArea[];
    loading: boolean;
    standardAreaList: [];
    totalCount: number;
    displayAreas: [];
    displayAreaList: [];
    displayAreaName: string;
  } {
    return {
      page: 1,
      size: 10,
      filter: {} as DisplayAreaFilter,
      standardAreas: [] as StandardArea[],
      loading: true,
      standardAreaList: [],
      totalCount: 0,
      displayAreas: [],
      displayAreaList: [],
      displayAreaName: this.displayName ?? '',
    };
  },
  computed: {
    labelName() {
      if (this.registration) {
        return '전시 지역 입력';
      }
      return '전시 지역 수정';
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    async createDisplayArea(): Promise<void | boolean> {
      this.$modal.show(
        {
          title: '전시 지역 추가',
          message: '전시 지역을 추가 하시겠습니까?',
          type: 'info',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            const param: DisplayAreaCreateParam = {
              parentId: this.parentId,
              name: this.$data.displayAreaName,
            };

            const newDisplayArea = await displayAreaService.createDisplayArea(
              param
            );

            this.$emit('addDisplayArea', newDisplayArea);

            this.closeModal();

            this.$modal.show({
              title: '전시 지역 등록 완료!',
              message: '전시 지역을 등록 완료했습니다.',
              type: 'success',
            });
          } catch (error) {
            this.$modal.show({
              title: '전시 지역 등록 실패!',
              message: '전시 지역 등록 실패했습니다.',
              type: 'warning',
            });
          }
        },
        () => {
          return false;
        }
      );
    },
    async modifyDisplayAreaName(
      id: string,
      displayName: string
    ): Promise<void> {
      console.log(id);
      const param: DisplayAreaModifyParam = {
        id,
        name: displayName,
      };

      console.log(displayName);

      this.$modal.show(
        {
          title: '전시 지역 수정!',
          html:
            `전시 지역을 <b style="color:black">` +
            displayName +
            `</b>(으)로 수정하시겠습니까?`,
          type: 'info',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            await displayAreaService.updateDisplayArea(param);

            this.$modal.show(
              {
                title: '전시 지역 수정 완료!',
                message: '전시 지역명을 수정하였습니다!',
                type: 'success',
              },
              () => {
                this.closeModal();
                this.$emit('modifyDisplayAreaName');
              }
            );
          } catch (error) {
            this.$modal.show({
              title: '전시 지역 수정 실패!',
              message:
                '전시 지역 수정을 실패했습니다.\n관리자에게 문의해주세요.',
              type: 'warning',
            });
          }
        },
        () => {
          return;
        }
      );
    },
  },
});
